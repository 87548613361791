.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 2rem auto;
}
