@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

.navigation {
  height: 60px;
  position: relative;;
  width: 100%;
  z-index: 10023;
  display: flex;
  align-items: center;
  position: relative;
  background-color:#000;
  font-family: 'Orbitron', sans-serif;
}
.brand-name {
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  /* margin-left: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  width: 700px;
  justify-content: space-between;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  /* text-decoration: none;
  display: block;
  width: 100%;
  color: yellow;
  font-size: 28px; */
  color: #fff;
  text-decoration: none;
  font-size: 28px;
  position: relative;
  display: block;
  transition: all 0.5s;
  text-transform: lowercase;
}
.navigation-menu li a span {
  color:#e4f500;
}

.navigation-menu li a span::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  top: 100%;
  left:0;
  background: #fff;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
}
.navigation-menu li a span:hover::after {
  transform:scaleX(1);
  transform-origin: left;
}




.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #2642af;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}


@media screen and (max-width: 768px) {
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: black;
    border-top: 1px solid black;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: #e4f500;
    width: 100%;
    padding: 1.5rem 0;
  }
}


@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu.expanded ul {
    display: block;
  }
}