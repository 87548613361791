.root {
  background-color: black;
  height: 100vh;
  overflow: hidden;
}
.container {
  max-width: 100%;
  height: calc(100vh - 60px);
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  background: black;
}