.outerCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  max-width: 550px;
  margin: 8rem 0 0 15rem;
}

.experienceRoot {
  margin-bottom: 1rem;
  text-align: left;
}

.experience {
  display: flex;
}
.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 3;
  margin-bottom: 1rem;
}

.year {
  font-weight: 600;
  font-size: 1rem;
  flex: 1;
  margin-top: .2rem;
}

.title {
  font-weight: 500;
  font-size: 1rem;
}

.company {
  font-weight: 600;
  font-size: 0.7rem;
  color: #4d4d4d;
  text-transform: uppercase;
}

.header {
  display: flex;
  margin: 0px 0px 2rem 10rem;
  font-size: 24px;
  color:#e4f500;
}


@media screen and (max-width: 768px) {
  .outerCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    max-width: 550px;
    margin: 4rem 0 1rem 3rem;
  }
  .header {
    display: flex;
    margin: 0 0 3rem 5rem;
    font-size: 24px;
    color:#e4f500;
  }
}


