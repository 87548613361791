@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');


.container {
  display: flex;
  justify-content: center;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
}

.container .top {
  display: flex;
  margin-top: 15rem;
}

.container .top .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;

}
.container .top .left .social-icons .icons{
  margin-left: 16px
}

.container .top .left h2 {
  color: #fff;
  font-size: 28px;
  font-family: 'Orbitron', sans-serif;
}

.container .top .right {
  width: 50%;
  font-size: 28px;
}



.container .bottom {
  display: flex;
  height: 20%;
  width: 100%;
  margin-top: 20rem;
  justify-content: flex-end;
  font-size: 28px;

}



@media screen and (max-width: 768px) {
  .container .top {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-left: 32px;
  }
  .container .top .left {
    width: 80%;
  }
  .container .top .right {
    width: 100%;
    margin-top: 32px;
  }
  .container .bottom {
    margin-top: 48px;
  }
}